import React from 'react';
import makeStyles from '@mui/styles/makeStyles';


import GridContainer from 'components/MaterialKit/Grid/GridContainer.js';
import GridItem from 'components/MaterialKit/Grid/GridItem.js';

import errorPageStyle from 'assets/jss/nextjs-material-kit-pro/pages/errorPageStyles.js';
import FrontSubsection from 'layouts/FrontSubsection';

const useStyles = makeStyles(errorPageStyle);

const ErrorPage = ({ ...rest }) => {
    const classes = useStyles();
    return (
        <>
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: `url(${require('assets/img/mines4.jpg')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                }}
            >
                <div className={classes.contentCenter}>
                    <GridContainer>
                        <GridItem md={12}>
                            <h1 className={classes.title}>404</h1>
                            <h2 className={classes.subTitle}>Page not found :(</h2>
                            <h4 className={classes.description}>
                Ooooups! Looks like you got lost.
                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </>
    );
};

ErrorPage.layout = FrontSubsection;

export default ErrorPage;